import React from 'react';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

export default function () {
  return (
    <>
      <h5 className="text-success mb-4">
        <FontAwesomeIcon icon={faCheck} className="me-3" size="2x" pull="left" />
        Good news! You may qualify for Extra Help from Medicare. Please click
        below to apply for Extra Help or call your plan so they can help you
        enroll in this program.
      </h5>

      <a
        href="https://secure.ssa.gov/i1020/start"
        className="btn btn-theme-button d-md-inline-block d-block"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => ReactGA.send({ hitType: 'pageview', page: '/apply-for-extra-help' })}
      >
        Apply for Extra Help
      </a>
    </>
  );
}
