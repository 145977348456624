const initialState = {
  site: process.env.REACT_APP_HOSTNAME || window.location.hostname,
  busy: false,
  missingDrugError: false,
  loaded: false,
  avgRetailPrices: {}, // dosage id -> avg price
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case 'STYLESHEET_LOADED':
      return { ...state, loaded: true };
    case 'UPDATE_THEME':
      return { ...state, [action.name]: action.value };
    case 'LOAD_THEME':
      return { ...state, ...action.theme };
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
