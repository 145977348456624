import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function CarrierTile({ id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    carriers: { logos },
    responses: { carrier },
  } = useSelector(store => store);
  const logo = logos[id];

  function setResponse(name, value) {
    dispatch({ type: 'SET_RESPONSE', name, value });
  }

  function handleClick() {
    setResponse('carrier', id);
    navigate('/plan');
  }

  const active = id === carrier;

  return (
    <div className="col col-lg-4 col-md-6 col-xl-3 text-center carrier-tile mb-md-3 mb-1">
      <button
        type="button"
        onClick={() => handleClick()}
        className={[
          'border shadow-sm w-100 h-100 text-smokygray btn btn-lg btn-white',
          active && 'h-100 border-info bg-lightblue',
        ].join(' ')}
        style={{ wordBreak: 'break-word' }}
      >
        {logo ? (
          <img src={logo} alt={`${id} logo`} className="img-fluid mh-100" />
        ) : (
          id
        )}
      </button>
    </div>
  );
}

export default CarrierTile;
