import axios from 'axios';

export default function getGACode() {
  const site = process.env.REACT_APP_HOSTNAME || window.location.hostname;
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/tracking/`,
    params: { site },
  })
    .then(data => data?.data?.tracking_code)
    .catch(() => '');
}
