import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  Button,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import CountySelection from './CountySelection';

function CountyModal({
  isOpen,
  close,
  counties,
  busy,
}) {
  return (
    <Modal isOpen={isOpen} toggle={close} size="lg" centered>
      <ModalBody className="px-5 py-4">
        <div className="d-flex justify-content-between mb-4">
          <h3>
            Which County?
            {busy && (
            <Spinner size="lg" className="ms-3" />
            )}
          </h3>
          <div className="text-nowrap">
            <Button color="white" size="sm" className="align-self-top" onClick={close}>
              close
              <FontAwesomeIcon icon={faTimes} className="ms-2" size="lg" />
            </Button>
          </div>
        </div>
        <div>
          {counties.map(({ CountyName, CountyFIPS }) => (
            <CountySelection name={CountyName} fips={CountyFIPS} key={CountyFIPS} />
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isOpen: state.modals.openModal === 'countypick',
  counties: state.counties.list,
  busy: state.theme.busy,
});

const mapDispatchToProps = {
  close: () => ({ type: 'CLOSE_MODAL' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CountyModal);
