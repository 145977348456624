const initialState = {
  keys: {}, // org name -> key
  keyTypes: {}, // key -> 'logo' | 'display' | 'org'
  orgNames: {}, // key -> org name list
  logos: {}, // key -> ?logo url
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_CARRIERS': {
      const { list = [] } = action;

      const keys = {};
      const keyTypes = {};
      const orgNames = {};
      const logos = {};
      list.forEach(({ org, display, logo }) => {
        const key = logo || display || org;
        const isDisplay = display ? 'display' : 'org';
        const type = logo ? 'logo' : isDisplay;

        keys[org] = key;
        keyTypes[key] = type;
        logos[key] = logo;

        orgNames[key] = orgNames[key] || [];
        orgNames[key].push(org);
      });

      return {
        ...state,
        keys,
        orgNames,
        logos,
        keyTypes,
      };
    }
    default:
      return state;
  }
};
