/* eslint-disable consistent-return */
import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

function InNeedBar({
  links = [],
  image,
  text,
}) {
  return (
    <div className="my-md-5 my-2 mmb-shadow container">
      <Row>
        {image && (
        <Col
          md="5"
          className="pe-4 img-cover content-img"
          style={{ backgroundImage: `url(${image})` }}
        />
        )}
        <Col className="py-5">
          <div className="mb-4" dangerouslySetInnerHTML={{ __html: text }} />

          <div>
            {links.map(({ name, url }) => (
              <div className="mb-2" key={name}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-theme-button d-md-inline-block d-block"
                  onClick={() => ReactGA.send({ hitType: 'pageview', path: name.toLowerCase().replaceAll(' ', '-') })}
                >
                  {name}
                </a>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

const EVENT_LISTENERS = {};

function addGAListener(id) {
  function cb() {
    ReactGA.send({ hitType: 'pageview', path: `/${id.replace('link-', '')}` });
  }

  setTimeout(() => {
    const el = document.getElementById(id);
    if (!EVENT_LISTENERS[id]) {
      el.addEventListener('click', cb);
      EVENT_LISTENERS[id] = true;
    }
  }, 500);
}

const mapStateToProps = (state) => {
  let text = '';
  try {
    const raw = JSON.parse(state.theme.contentBox2?.body);
    const contentState = convertFromRaw(raw);
    const options = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type');
        if (entityType === 'LINK') {
          const { className, innerText } = entity.getData();
          let { url } = entity.getData();
          if (url && (!url.includes('http://') && !url.includes('https://'))) {
            url = `https://${url}`;
          }

          let id = '';

          if (innerText) {
            id = `link-${innerText.toLowerCase().replaceAll(' ', '-')}`;
            try {
              addGAListener(id);
            // if this errors we don't want to crash the display of the text
            // so catch it and log it instead
            } catch (err) { console.log(err); }
          }

          return {
            element: 'a',
            attributes: {
              href: url,
              className,
              target: '_blank',
              id,
            },
          };
        }
      },
    };
    text = stateToHTML(contentState, options);
  } catch (error) {
    // nothing to do
  }

  return {
    links: state.theme.contentBox2?.buttons,
    // We cannot use encodeURIComponent as https:// will be encoded too
    image: state.theme.images?.contentBox2Image.replace(/ /g, '%20'),
    text,
  };
};

export default connect(mapStateToProps)(InNeedBar);
