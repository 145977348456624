import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import formularySearch from '../../actions/formulary-search';

function CountySelection({ name, fips }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { busy } = useSelector(store => store.theme);

  // dispatch functions
  function close() {
    dispatch({ type: 'CLOSE_MODAL' });
  }
  function loadPlans(list) {
    dispatch({ type: 'LOAD_PLANS', list });
  }
  function loadFormulary(list) {
    dispatch({ type: 'LOAD_FORMULARY', list });
  }
  function setBusy(value) {
    dispatch({ type: 'UPDATE_THEME', name: 'busy', value });
  }

  async function handleClick() {
    try {
      setBusy(true);

      const { Plans, DrugFormularies } = await formularySearch({ county: fips });
      await Promise.all([
        loadPlans(Plans),
        loadFormulary(DrugFormularies),
      ]);

      close();
      navigate('/carrier');
    } catch (error) {
      await close();
      dispatch({ type: 'OPEN_MODAL', id: 'countyerror' });
    } finally {
      setBusy(false);
    }
  }

  return (
    <div className="d-sm-inline-block d-block mb-2 mx-sm-1">
      <button
        type="button"
        className="btn btn-theme-button"
        onClick={() => handleClick()}
        disabled={busy}
      >
        {`${name} County`}
      </button>
    </div>
  );
}

export default CountySelection;
