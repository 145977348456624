const initialState = {
  list: [], // all formulary
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_FORMULARY': {
      const list = action.list.filter(({ DaysSupply }) => DaysSupply === 30);
      return { ...state, list };
    }
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
