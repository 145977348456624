import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

export class PrivacyPolicy extends React.PureComponent {
  componentDidMount() {
    const checkIFrame = () => {
      const iframe = document.getElementById('privacypolicyIFrame');

      if (iframe.contentDocument !== null) {
        const { docUrl } = this.props;
        iframe.src = `//docs.google.com/gview?url=${docUrl}&embedded=true`;
      } else {
        window.clearInterval(this.check);
      }
    };

    this.check = window.setInterval(checkIFrame, 2000);
  }

  componentWillUnmount() {
    window.clearInterval(this.check);
  }

  render() {
    const { docUrl } = this.props;
    return (
      <Container>
        <Link to="/" className="btn btn-white btn-sm my-2">
          <FontAwesomeIcon icon={faArrowLeft} className="me-1" size="lg" />
          Go Back
        </Link>
        <iframe
          id="privacypolicyIFrame"
          title="privacypolicy"
          className="w-100 doc-embed"
          src={`//docs.google.com/gview?url=${docUrl}&embedded=true`}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  docUrl: state.theme.privacyUrl,
});

export default connect(mapStateToProps)(PrivacyPolicy);
