import axios from 'axios';
import { store } from '../store';

export const fipsSearch = async (zip) => {
  const { site } = store.getState().theme;

  const { data } = await axios({
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/geographics/zip/${zip}/`,
    params: { site },
  });
  return data;
};

export const idSearch = async (rawid = '') => {
  const { site } = store.getState().theme;
  const qid = rawid.replace('-', '').replace(' ', '');

  const { data } = await axios({
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/search/`,
    params: { site, qid },
  });

  if (!data.length) {
    throw new Error('No plans found');
  }
  return data;
};
