import axios from 'axios';
import { store } from '../store';

export default async ({ county, plans }) => {
  const { site, dosages } = store.getState().theme;

  const dosageIDs = dosages.map((d) => d.id);

  const { data } = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/formulary/`,
    data: { county, dosages: dosageIDs, plan_ids: plans },
    params: { site },
  });

  if (!data?.Plans?.length) {
    throw new Error();
  }
  return data;
};
