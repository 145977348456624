import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import PlanYearRow from './PlanYearRow';
import DeductibleRow from './DeductibleRow';
import RowHeader from '../RowHeader';
import DeductiblePhaseRow from './DeductiblePhaseRow';

function BenefitsTableTall({ years, formulary, avgRetail }) {
  return (
    <Table borderless className="my-4 benefits-table tall d-lg-none text-theme-text">
      <tbody>
        <PlanYearRow years={years} />
        <DeductibleRow name="deductible" years={years} formulary={formulary}>
          <RowHeader id="ded-tall" title="Deductible">
            A deductible is the amount that you pay before the health plan provides coverage.
          </RowHeader>
        </DeductibleRow>
        <DeductiblePhaseRow name="before" years={years} formulary={formulary}>
          <RowHeader id="beforeded-tall" title="Before Deductible">
            Deductible means the amount you must pay each year for all prescriptions combined
            before your plan pays its share.
          </RowHeader>
        </DeductiblePhaseRow>
        <DeductiblePhaseRow name="after" years={years} formulary={formulary}>
          <RowHeader id="afterded-tall" title="After Deductible">
            Deductible means the amount you must pay each year for all prescriptions combined
            before your plan pays its share.
          </RowHeader>
        </DeductiblePhaseRow>
        {/* Coverage Gap does not exist for 2025, removing
        <DeductiblePhaseRow name="gap" years={years} formulary={formulary}>
          <RowHeader id="gap-tall" title="Coverage Gap">
            The Coverage Gap (sometimes called the “donut hole”) begins when you and the plan
            have spent $4,660 on covered drugs in 2023, or $5,300 in 2024.
          </RowHeader>
        </DeductiblePhaseRow>
        */}
        <DeductiblePhaseRow name="cata" years={years} formulary={formulary}>
          <RowHeader id="cata-tall" title="Catastrophic Coverage">
            Catastrophic Coverage begins once you&apos;ve spent $8,000 on covered drugs in 2024,
            or $2,000 on covered drugs in 2025.
          </RowHeader>
        </DeductiblePhaseRow>
        {avgRetail && (
        <DeductibleRow name="avgRetail" years={years} formulary={formulary}>
          <RowHeader id="avgretail-tall" title="Average Retail Price">
            Average Retail Price is the average drug cost according to drug
            chain stores nationwide.
          </RowHeader>
        </DeductibleRow>
        )}
      </tbody>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  avgRetail: state.theme.avgRetail,
});

export default connect(mapStateToProps)(BenefitsTableTall);
