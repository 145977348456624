import React from 'react';
import { Col, Table } from 'reactstrap';

function CostsTable({ group, avg, last }) {
  return (
    <Col xl="4" md="6">
      <Table borderless className="mt-4 costs-table">
        <thead>
          <tr>
            <th>Month</th>
            <th>Estimated Cost</th>
          </tr>
        </thead>
        <tbody>
          {group.map(({ month, year, cost }) => (
            <tr key={`${month}${year}`}>
              <th scope="row">{`${month} ${year}`}</th>
              <td>{`$${cost}`}</td>
            </tr>
          ))}
          {last && (
          <tr className="avg-row">
            <th scope="row">
              {`${group[0].year} Average`}
            </th>
            <td>{`$${avg.toFixed(2)}`}</td>
          </tr>
          )}
        </tbody>
      </Table>
    </Col>
  );
}

export default CostsTable;
