import React from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import CarrierTile from './CarrierTile';
import sortAlphabetically from '../../util/sort';

class CarrierSelection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { carriers: [] };
  }

  componentDidMount() {
    // Only show carriers with plans in this zip.
    const { plans, carrierKeys, keyTypes } = this.props;

    // Union for duplicates.
    const keySet = new Set();
    plans.forEach(({ OrganizationName }) => {
      const key = carrierKeys[OrganizationName];
      keySet.add(key);
    });

    // Separate out logos vs display names vs org names.
    const keyList = [...keySet];
    const logos = keyList.filter((key) => keyTypes[key] === 'logo');
    const displays = keyList.filter((key) => keyTypes[key] === 'display');
    const orgs = keyList.filter((key) => keyTypes[key] === 'org');

    console.log('logos: ', logos);
    console.log('displays: ', displays);
    console.log('orgs.length: ', orgs.length);
    console.log('orgs: ', orgs);

    // sort all carriers alphabetically
    const carriers = [
      ...logos,
      ...displays,
      ...orgs,
    ].sort(sortAlphabetically);

    console.log('carriers: ', carriers);

    this.setState({ carriers });
  }

  render() {
    const { carriers } = this.state;

    return (
      <Container id="formulary-body" className="my-md-5 p-xl-5 p-4">
        <h1 className="text-center text-theme-header mb-5">
          Select your insurance carrier
        </h1>

        <Row>
          {carriers.map((key) => (
            <CarrierTile id={key} key={key} />
          ))}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: state.plans.list,
  carrierKeys: state.carriers.keys,
  keyTypes: state.carriers.keyTypes,
  carrier: state.responses.carrier,
});

export default connect(mapStateToProps)(CarrierSelection);
