import React from 'react';

function RowHeader({ title, children }) {
  return (
    <th className="px-1 text-right">
      <tr style={{ height: '2rem' }}>
        {title}
      </tr>
      {children && (
        <tr style={{ color: 'var(--bs-smokygray)' }}>
          {children}
        </tr>
      )}
    </th>
  );
}

export default RowHeader;
