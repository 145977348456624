import React from 'react';
import RowHeader from '../RowHeader';

function PlanYearRow({ years }) {
  return (
    <tr>
      <RowHeader id="year-tall" title="Plan Year" />
      {years.map((year) => (
        <td key={year}>{year}</td>
      ))}
    </tr>
  );
}

export default PlanYearRow;
