import React from 'react';

function DeductibleRow({
  years,
  formulary,
  name,
  children,
}) {
  return (
    <tr>
      {children}
      {years.map((year) => (
        <td key={year}>{`$${formulary[year][name]}`}</td>
      ))}
    </tr>
  );
}

export default DeductibleRow;
