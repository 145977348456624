import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import sampleCard1 from '../../static/img/Medicare sample card-01b.png';
import sampleCard2 from '../../static/img/Medicare sample card-02b.png';
import sampleCard3 from '../../static/img/Medicare sample card-03b.png';

function CardModal({ isOpen, close }) {
  return (
    <Modal isOpen={isOpen} toggle={close} size="lg" centered>
      <ModalBody className="px-5 py-4">
        <div className="d-flex justify-content-between">
          <h2 className="mt-3">How to find your Plan ID</h2>
          <div className="text-nowrap">
            <Button color="white" size="sm" className="align-self-top" onClick={close}>
              close
              <FontAwesomeIcon icon={faTimes} className="ms-2" size="lg" />
            </Button>
          </div>
        </div>

        <p>
          The Plan ID is on your Member ID card from the health insurer that
          provides you Medicare drug coverage.
        </p>

        <p>
          Look for H, S, or R followed by a 4-digit number, then an additional
          3-digit number. They may be separated by a space or a hyphen. If there
          is an additional 3-digit number after that, please enter it as well.
        </p>

        <div className="d-flex">
          <div>Examples:</div>
          <div>
            <span className="ms-3 d-inline-block">H1234 001</span>
            <span className="ms-3 d-inline-block">S1234-001</span>
            <span className="ms-3 d-inline-block">R1234 001</span>
            <span className="ms-3 d-inline-block">H1234-123-123</span>
          </div>
        </div>

        <Row className="no-gutters">
          <Col lg="6">
            <img src={sampleCard1} alt="" className="img-fluid" />
          </Col>
          <Col lg="6">
            <img src={sampleCard2} alt="" className="img-fluid" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="6">
            <img src={sampleCard3} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isOpen: state.modals.openModal === 'plansearch',
});

const mapDispatchToProps = {
  close: () => ({ type: 'CLOSE_MODAL' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CardModal);
