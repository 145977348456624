/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

function DosageBar({
  dosages,
  selectedDosage,
  setResponse,
  qid,
  planID,
  plansMap,
  carrier,
}) {
  const { name: label } = dosages.find(d => (String(d.id) === String(selectedDosage)));

  return (
    <div className="border-top border-bottom bg-theme-background shadow-sm py-4 text-center">
      <Container className="text-theme-background-diff">
        <h5 className="mb-0">Medicare drug coverage & costs:</h5>
        <h2 className="my-3">
          {label}
        </h2>
        <div className="d-flex align-items-center justify-content-center">
          <span className="text-nowrap me-2">Dosage:</span>
          <select
            id="dosageselect"
            type="select"
            className="w-auto form-select"
            value={selectedDosage}
            tabIndex={0}
            onChange={(e) => {
              const dName = dosages.find((d) => Number(d.id) === Number(e.target.value)).name;
              const planName = plansMap[planID].PlanName;
              const dimensions = {
                carrier,
                plan_name: planName || 'Uknown',
                qid,
                dosage: dName,
                org_name: plansMap[planID].OrganizationName,
              };
              ReactGA.event('changed_dosage', dimensions);
              setResponse(e);
            }}
          >
            {dosages.map(({ id, name }) => (
              <option value={id} key={id}>{name}</option>
            ))}
          </select>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  carrier: state.responses.carrier,
  dosages: state.theme.dosages,
  selectedDosage: state.responses.dosage,
  qid: state.responses.qid,
  planID: Number(state.responses.plan),
  plansMap: state.plans.map,
});

const mapDispatchToProps = {
  setResponse: ({ target: { value } }) => ({ type: 'SET_RESPONSE', name: 'dosage', value }),
};

export default connect(mapStateToProps, mapDispatchToProps)(DosageBar);
