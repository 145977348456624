import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

function BenefitsHeader({
  carrier,
  logo,
  plan,
  type,
}) {
  return (
    <Container className="py-md-5 py-4">
      <Row>
        <Col sm="6" className="mb-3 mb-sm-0">
          Below is the estimated drug cost. Your out-of-pocket costs can vary
          throughout the year, depending on how much you have spent on all
          your drugs at the time you fill your prescription.
        </Col>
        <Col className="pl-sm-5 line-height-sm">
          <Row>
            <Col md="auto">
              <strong>Your insurance carrier:</strong>
            </Col>
            <Col>
              <div className="pl-md-0 ps-3">
                {logo ? (
                  <img
                    src={logo}
                    alt={carrier}
                    className="img-fluid carrier-logo-sm"
                  />
                ) : (
                  carrier
                )}
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Col md="auto">
              <strong>Your plan name:</strong>
            </Col>
            <Col>
              <div className="pl-md-0 ps-3">
                {plan}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              <strong>Your plan type:</strong>
            </Col>
            <Col>
              <div className="pl-md-0 ps-3">
                {type === 'PDP' && 'Prescription Drug Plan (PDP)'}
                {type === 'MAPD' && 'Medicare Advantage Prescription Drug (MAPD)'}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = ({
  plans,
  responses: { carrier, plan },
  carriers: { logos, keys },
}) => {
  const org = plans.map[plan]?.OrganizationName;
  const key = carrier || keys[org];

  return {
    carrier: key,
    logo: logos[key],
    plan: plans.map[plan]?.PlanName,
    type: plans.map[plan]?.PlanTypeDesc,
  };
};

export default connect(mapStateToProps)(BenefitsHeader);
