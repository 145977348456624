import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import HeaderTitle from '../RowHeader';
import Row from './BenefitsRow';

function BenefitsTableWide({ years, formulary, avgRetail }) {
  return (
    <Table borderless responsive className="my-4 benefits-table wide d-lg-table d-none text-theme-text">
      <thead>
        <tr>
          <th id="year-wide" className="px-1 pt-4 text-right">
            Plan Year
          </th>
          <HeaderTitle id="ded-wide" title="Deductible">
            A deductible is the amount that you pay before the health plan provides coverage.
          </HeaderTitle>
          <HeaderTitle id="beforeded-wide" title="Before Deductible Is Met">
            Deductible means the amount you must pay each year for all prescriptions combined
            before your plan pays its share.
          </HeaderTitle>
          <HeaderTitle id="afterded-wide" title="After Deductible Is Met">
            The copay or co-insurance amount you must pay for each prescription after your plan
            year Deductible has been met.
          </HeaderTitle>
          {/* Coverage Gap does not exist for 2025, removing
          <HeaderTitle id="gap-wide" title="Coverage Gap">
            The Coverage Gap (sometimes called the “donut hole”) begins when you and the plan
            have spent $4,660 on covered drugs in 2023, or $5,300 in 2024.
          </HeaderTitle>
          */}
          <HeaderTitle id="cata-wide" title="Catastrophic Coverage">
            Catastrophic Coverage begins once you&apos;ve spent $8,000 on covered drugs in 2024,
            or $2,000 on covered drugs in 2025.
          </HeaderTitle>
          {avgRetail && (
          <HeaderTitle id="avgretail-wide" title="Average Retail Price">
            Average Retail Price is the average drug cost according to drug
            chain stores nationwide.
          </HeaderTitle>
          )}
        </tr>
      </thead>
      <tbody>
        {years.map((year) => (
          <Row year={year} formulary={formulary[year]} key={year} />
        ))}
      </tbody>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  avgRetail: state.theme.avgRetail,
});

export default connect(mapStateToProps)(BenefitsTableWide);
