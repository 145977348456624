import React, { useState } from 'react';
import { connect } from 'react-redux';

function ColorTheme({ path, done }) {
  const [hasLoaded, setLoaded] = useState(false);
  const handleOnLoad = () => {
    setLoaded(true);
    done();
  };

  /**
   * The onLoad event of link element is not reliable past the very first
   * event. For example, when the path changes to null and then back to valid
   * string, it will not trigger onLoad a 2nd time. To deal with this, use
   * component state that will persist through redux clears and theme changes.
   */
  if (hasLoaded) {
    done();
  }

  return (
    <link rel="stylesheet" type="text/css" href={path} onLoad={handleOnLoad} />
  );
}

const mapStateToProps = (state) => ({
  path: state.theme.cssThemeLink,
});

const mapDispatchToProps = {
  done: () => ({ type: 'STYLESHEET_LOADED' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorTheme);
