const initialState = {
  openModal: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, openModal: action.id };
    case 'CLOSE_MODAL':
      return { ...state, openModal: null };
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
