/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentExclamation } from '@fortawesome/pro-light-svg-icons';

export default function () {
  return (
    <>
      <h5 className="text-danger mb-4">
        <FontAwesomeIcon icon={faCommentExclamation} className="me-3" size="2x" pull="left" />
        Unfortunately, you may not qualify for Extra Help from Medicare, but your
        state may have programs that can help with prescription costs. Please click
        below to contact resources in your state for more information.
      </h5>

      <a
        role="button"
        tabIndex={0}
        eventLabel="//www.medicaid.gov/about-us/contact-us/contact-your-state-questions/index.html"
        href="//www.medicaid.gov/about-us/contact-us/contact-your-state-questions/index.html"
        className="btn btn-theme-button me-md-3 mb-md-0 mb-2 d-md-inline-block d-block"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => ReactGA.send({ hitType: 'pageview', page: '/contact-medicaid' })}
      >
        Contact Medicaid
      </a>
      <a
        role="button"
        tabIndex={0}
        eventLabel="//www.shiptacenter.org/"
        href="//www.shiptacenter.org/"
        className="btn btn-theme-button d-md-inline-block d-block"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => ReactGA.send({ hitType: 'pageview', page: '/ship' })}
      >
        Contact State Health Insurance Assistance Program (SHIP)
      </a>
    </>
  );
}
