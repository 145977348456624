/* eslint-disable consistent-return */
import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

function ExtraHelpBar({ links = [], image, text }) {
  return (
    <Container className="my-md-5 my-2 mmb-shadow">
      <Row>
        {image && (
        <Col
          md="4"
          className="pe-4 img-cover content-img"
          style={{ backgroundImage: `url(${image})` }}
        />
        )}
        <Col className="py-5">
          <div className="mb-4" dangerouslySetInnerHTML={{ __html: text }} />

          <div>
            <Link
              to="/calc"
              className="btn btn-theme-button d-md-inline-block d-block"
            >
              Extra Help Calculator
            </Link>
            {links.map(({ name, url }) => (
              <div className="mt-2" key={name}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-theme-button d-md-inline-block d-block"
                  onClick={() => ReactGA.send({ hitType: 'pageview', path: name.toLowerCase().replaceAll(' ', '-') })}
                >
                  {name}
                </a>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  let text = '';
  try {
    const raw = JSON.parse(state.theme.contentBox1?.body);
    const contentState = convertFromRaw(raw);
    const options = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type');
        if (entityType === 'LINK') {
          const { className } = entity.getData();
          let { url } = entity.getData();
          // Object's className set in manage-frontend, either of LINK or BTN
          // We do this because there is only a draftjs 'LINK' type, but we want
          // Buttons *and* Links
          if (url && (!url.includes('http://') && !url.includes('https://'))) {
            url = `https://${url}`;
          }
          return {
            element: 'a',
            attributes: {
              href: url,
              className,
              target: '_blank',
            },
            style: {
              /* Used if we need to override bootstrap button styling above */
              /* docs: https://github.com/sstur/draft-js-utils/tree/master/packages/draft-js-export-html */
              /* ...data.style */
            },
          };
        }
      },
    };
    text = stateToHTML(contentState, options);
  } catch (error) {
    // nothing to do
  }

  return {
    links: state.theme.contentBox1?.buttons,
    image: state.theme.images?.contentBox1Image.replace(/ /g, '%20'),
    text,
  };
};

export default connect(mapStateToProps)(ExtraHelpBar);
