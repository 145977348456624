import React from 'react';

function BenefitPhase({ dosage, alt = '-' }) {
  if (!dosage) {
    return <td>{alt}</td>;
  }
  if (typeof dosage === 'string') {
    return <td>{dosage}</td>;
  }

  const { NonPrefPaymentDesc, NonPrefPaymentValue } = dosage;
  return (
    <td>
      {NonPrefPaymentDesc.toLowerCase() === 'coinsurance' ? (
        `You pay ${Math.floor(NonPrefPaymentValue * 100)}%`
      ) : (
        `You pay $${NonPrefPaymentValue}`
      )}
    </td>
  );
}

export default BenefitPhase;
