import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function WithTracker({ children, options = {} }) {
  const location = useLocation();
  useEffect(() => {
    const trackPage = (page) => {
      console.log('trackPage.page: ', page);
      ReactGA.set({ page, ...options }, ['tracker1', 'tracker2']);
      ReactGA.send({
        hitType: 'pageview',
        page,
      });
    };
    trackPage(location.pathname);
  }, [location, options]);

  return children;
}

export default WithTracker;
