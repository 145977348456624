import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faWifiSlash, faCompassSlash } from '@fortawesome/pro-light-svg-icons';

function CountyErrorModal({
  erroring,
  missing,
  close,
  zip,
}) {
  return (
    <Modal isOpen={erroring || missing} toggle={close} size="lg" centered>
      <ModalBody className="px-5 py-4">
        <div className="text-right text-nowrap">
          <Button color="white" size="sm" className="align-self-top" onClick={close}>
            close
            <FontAwesomeIcon icon={faTimes} className="ms-2" size="lg" />
          </Button>
        </div>
        {erroring && (
        <div className="text-center">
          <h4 className="text-danger mb-4">
            <FontAwesomeIcon icon={faWifiSlash} size="2x" />
          </h4>
          <h5>
            A network error has occurred. Please check your connection and try again.
          </h5>
        </div>
        )}
        {missing && (
        <div className="text-center">
          <h3 className="text-danger">
            <FontAwesomeIcon icon={faCompassSlash} className="mb-2" size="2x" />
          </h3>
          <h5>
            <span>No counties were found for </span>
            {zip}
            <span>. Please check your zip code and try again.</span>
          </h5>
        </div>
        )}
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  erroring: state.modals.openModal === 'countyerror',
  missing: state.modals.openModal === 'countynone',
  zip: state.responses.zip,
});

const mapDispatchToProps = {
  close: () => ({ type: 'CLOSE_MODAL' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CountyErrorModal);
