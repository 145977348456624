import axios from 'axios';
import { store } from '../store';

// `site.com?preview` will load the unpublished preview site theme.
const preview = new URLSearchParams(window.location.search).has('preview');

/**
 * Pull down the latest published theme.
 */
export const loadTheme = async (hash) => {
  const { site } = store.getState().theme;

  const { data } = await axios({
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/theme/`,
    params: { site, hash },
  });
  store.dispatch({ type: 'LOAD_THEME', theme: { ...data } });
};

/**
 * Check for any published theme updates.
 */
export const validateTheme = async () => {
  const { site } = store.getState().theme;

  try {
    const { data } = await axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/config/theme/versions/`,
      params: { site },
    });
    const current = store.getState().theme.hash;
    const hash = preview ? data.draft : data.live;

    if (hash !== current) {
      await loadTheme(hash);
    }
  } catch (error) {
    store.dispatch({ type: 'THEME_ERROR' });
  }
};
