import axios from 'axios';
import { store } from '../store';

export default async () => {
  const { site } = store.getState().theme;

  const { data } = await axios({
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/logos/`,
    params: { site },
  });

  if (!data?.length) {
    throw new Error();
  }

  store.dispatch({
    type: 'LOAD_CARRIERS',
    list: data.map((org) => ({
      org: org.organization_name,
      display: org.display_name,
      logo: org.url,
    })),
  });
};
