/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import InNeedBar from '../benefits/InNeedBar';
import YesBanner from './YesBanner';
import NoBanner from './NoBanner';

function Calculator() {
  const [status, setStatus] = useState(null);
  const [income, setIncome] = useState('');
  const [savings, setSavings] = useState('');

  const handleStatusChange = ({ target: { value } }) => setStatus(value);
  const handleIncomeChange = ({ target: { value } }) => setIncome(value);
  const handleSavingsChange = ({ target: { value } }) => setSavings(value);

  return (
    <>
      <Container className="mt-4 mb-md-4 mb-1 p-xl-5 p-4 border shadow-sm">
        <Link to="/benefits" className="btn btn-white btn-sm font-weight-bold fs-5">
          <FontAwesomeIcon icon={faArrowLeft} className="me-1" size="lg" />
          Go Back
        </Link>
        <h1 className="text-center text-theme-header mb-4">
          Extra Help Calculator
        </h1>

        <p>
          {`This calculator will help you determine if you may qualify for
          Extra Help from Medicare to help lower your prescription costs,
          premiums, deductibles, and co-insurance of Medicare prescription
          drug coverage for ${moment().year()}. Please select the appopriate answer
          for the questions below.`}
        </p>

        <div className="mt-4">
          <h5>Are you applying as:</h5>
          <div className="ms-3">
            <div className="d-flex flex-row" style={{ lineHeight: '22px' }}>
              <div className="form-check">
                <input
                  id="status-single"
                  className="form-check-input"
                  name="status"
                  type="radio"
                  onChange={handleStatusChange}
                  value="single"
                  checked={status === 'single'}
                  tabIndex={0}
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="status-single"
                >
                  Individual
                </label>
              </div>
            </div>
            <div className="d-flex flex-row" style={{ lineHeight: '22px' }}>
              <div className="form-check">
                <input
                  id="status-married"
                  className="form-check-input"
                  name="status"
                  type="radio"
                  onChange={handleStatusChange}
                  value="married"
                  checked={status === 'married'}
                  tabIndex={0}
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="status-married"
                >
                  Married Couple
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="my-4">
          <h5>What is your monthly income?</h5>
          <div className="ms-3">
            <select
              id="income-select"
              className="form-control"
              type="select"
              onChange={handleIncomeChange}
              value={income}
              disabled={!status}
              tabIndex={0}
            >
              <option value="">----</option>
              {status === 'single' ? (
                <>
                  <option value="upper">More than $1,882.50</option>
                  <option value="lower">Up to $1,882.50</option>
                </>
              ) : (
                <>
                  <option value="upper">More than $2,555</option>
                  <option value="lower">Up to $2,555</option>
                </>
              )}
            </select>
          </div>
        </div>

        <div>
          <h5>
            How much do you have in cash, savings, stocks, IRAs,
            401k, whole life insurance, and non-primary home?
          </h5>
          <div className="ms-3">
            <select
              id="savings-select"
              className="form-control"
              type="select"
              onChange={handleSavingsChange}
              value={savings}
              disabled={!status}
              tabIndex={0}
            >
              <option value="">----</option>
              {status === 'single' ? (
                <>
                  <option value="upper">More than $17,220</option>
                  <option value="lower">Up to $17,220</option>
                </>
              ) : (
                <>
                  <option value="upper">More than $34,360</option>
                  <option value="lower">Up to $34,360</option>
                </>
              )}
            </select>
          </div>
        </div>

        {income && savings && (
          <div className="ms-3 mt-4">
            {income === 'lower' && savings === 'lower' ? (
              <YesBanner />
            ) : (
              <NoBanner />
            )}
          </div>
        )}
      </Container>

      <InNeedBar />
    </>
  );
}

export default Calculator;
