import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Spinner } from 'reactstrap';
import HTMLReactParser from 'html-react-parser';
import { validateTheme } from '../../actions/theme';
import ZipSearch from './ZipSearch';
import Disclaimer from './Disclaimer';

function Landing() {
  const dispatch = useDispatch();
  const {
    banner,
    drugs = [],
    findOut,
    guideName,
    ifMedicarePartD,
    images: {
      entryPageImage: image,
    } = {},
    loaded,
  } = useSelector(store => store.theme);

  const clearState = () => dispatch({ type: 'CLEAR' });

  useEffect(() => {
    (async () => {
      await clearState();
      await validateTheme();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drugName = drugs[0];

  if (!loaded) {
    return (
      <div id="landing" className="flex-column justify-content-center">
        <h1 className="mb-5 text-center">Loading your guide</h1>
        <div className="d-flex justify-content-center align-items-center">
          <Spinner className="me-4" style={{ width: '3rem', height: '3rem' }} type="grow" />
          <Spinner className="me-4" style={{ width: '3rem', height: '3rem' }} type="grow" />
          <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
        </div>
      </div>
    );
  }

  return (
    <div id="landing">
      {banner && (
        <div
          className="justify-content-center py-2"
          style={{
            position: 'absolute',
            top: '0px',
            backgroundColor: '#a6bd81',
            width: '100vw',
            textAlign: 'center',
          }}
        >
          <div className="font-weight-bold fs-3">
            {String(banner.headerText).replaceAll('{drugName}', drugName)}
          </div>
          <div className="fs-5">
            {HTMLReactParser(String(banner.bodyText).replaceAll('{drugName}', drugName))}
          </div>
        </div>
      )}
      <Col
        xl="6"
        lg="6"
        className="img-cover content-img"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <Col className="responsive-padding" style={{ alignSelf: 'center' }}>
        <h5 className={`mb-3 text-muted ${banner ? 'mt-5' : ''}`}>
          {guideName}
        </h5>
        <h2 style={{ fontSize: '2.5rem' }} className="mb-4 p-0 text-theme-header line-height-sm font-weight-bold">
          <span className="d-xl-block d-none">
            {findOut ? String(findOut).replaceAll('{drugName}', drugName) : `See how ${drugName} is covered by your Medicare plan.`}
          </span>
        </h2>
        {ifMedicarePartD && (
          <div className="fs-4">
            {ifMedicarePartD}
          </div>
        )}
        <ZipSearch />
        <Disclaimer />
      </Col>
    </div>
  );
}

export default Landing;
