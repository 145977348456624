import { combineReducers } from 'redux';

import theme from './theme';
import modals from './modals';
import responses from './responses';
import plans from './plans';
import counties from './counties';
import carriers from './carriers';
import formulary from './formulary';
import dosages from './dosages';

export default () => combineReducers({
  theme,
  modals,
  responses,
  plans,
  counties,
  carriers,
  formulary,
  dosages,
});
