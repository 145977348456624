import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import createRootReducer from './reducers/root-reducer';

const initialState = {};
const middleware = [thunk];
const enhancers = [];

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistReducer({ key: 'conformlook', storage }, createRootReducer()),
  initialState,
  composeEnhancers(applyMiddleware(...middleware), ...enhancers),
);

export const persistor = persistStore(store);
