const initialState = {
  list: [], // all plans
  map: {}, // PlanID -> plan details
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_PLANS': {
      const map = action.list.reduce((_map, { PlanID, ...rest }) => ({
        ..._map,
        [PlanID]: { ...rest },
      }), {});

      return {
        ...state,
        list: [...action.list],
        map,
        erroring: false,
      };
    }
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
