import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import CostsTable from './CostsTable';

function CostsBar({ costs = [], busy, erroring }) {
  const yearsGroups = {};
  costs.forEach((cost) => {
    yearsGroups[cost.year] = yearsGroups[cost.year] || [];
    yearsGroups[cost.year].push(cost);
  });

  const groups = [];
  Object.keys(yearsGroups).forEach((year, yearIndex) => {
    groups[yearIndex] = [];

    yearsGroups[year].forEach((cost, i) => {
      const j = Math.floor(i / 6);
      groups[yearIndex][j] = groups[yearIndex][j] || [];
      groups[yearIndex][j].push(cost);
    });
  });

  return (
    <Container className="pt-3">
      <h1>Your estimated costs</h1>
      <div>
        The estimated costs are intended to allow you to gauge approximate costs. They
        assume that this is your only prescription medication, and that you begin
        filling the prescription next month. Your actual costs will vary based on the
        pharmacy, the costs for you other prescriptions, and when you begin and end
        each prescription. Note that these estimates include your monthly premium.
      </div>

      <Row className="d-flex justify-content-end">
        {busy && (
          <Col className="text-center py-5">
            Loading Costs
            <Spinner size="sm" className="ms-2" />
          </Col>
        )}
        {erroring && (
          <Col className="text-center text-danger py-5">
            <h6>A network error has occurred. Please check your connection and try again.</h6>
          </Col>
        )}

        {!erroring && groups.map((yearGroups) => {
          let sum = 0;
          let len = 0;

          yearGroups.forEach((group) => group.forEach(({ cost }) => {
            sum += cost;
            len += 1;
          }));

          const avg = sum / len;

          return yearGroups.map((group, i) => (
            <CostsTable
              group={group}
              avg={avg}
              last={i === yearGroups.length - 1}
              key={`${group[0].month}${group[0].year}`}
            />
          ));
        })}
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  costs: state.benefits?.costs,
  busy: state.theme.busy,
  erroring: state.benefits?.erroring,
});

export default connect(mapStateToProps)(CostsBar);
