import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { validateTheme } from './actions/theme';
import loadCarriers from './actions/carriers';
import ColorTheme from './components/ColorTheme';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/nav/Footer';
import Landing from './views/landing';
import Carrier from './views/carrier';
import Plan from './views/plan';
import Benefits from './views/benefits';
import Calculator from './views/calculator';
import Privacy from './views/privacy';
import Terms from './views/terms';
import WithTracker from './hocs/WithTracker';

import getGACode from './util/tracking';

const setupGA = () => {
  getGACode().then(code => {
    ReactGA.initialize(code);
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  });
};

class App extends React.PureComponent {
  async componentDidMount() {
    await Promise.all([
      validateTheme(),
      loadCarriers(),
    ]);
    window.setInterval(validateTheme, 8000);

    setupGA();
    this.updateMeta();
  }

  componentDidUpdate() {
    this.updateMeta();
  }

  updateMeta = () => {
    const {
      favicon,
      ati72,
      ati120,
      ati152,
    } = this.props;

    document.getElementById('favicon').href = favicon;
    document.getElementById('ati-72').href = ati72;
    document.getElementById('ati-120').href = ati120;
    document.getElementById('ati-152').href = ati152;
  };

  render() {
    return (
      <BrowserRouter>
        <WithTracker>
          <ScrollToTop>
            <ColorTheme />
            <Routes>
              <Route path="/carrier" element={<Carrier />} />
              <Route path="/plan" element={<Plan />} />
              <Route path="/benefits" element={<Benefits />} />
              <Route path="/calc" element={<Calculator />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/*" element={<Landing />} />
            </Routes>
            <Footer />
          </ScrollToTop>
        </WithTracker>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  gaCode: state.theme.gaTrackingId,
  favicon: state.theme.images?.favicon,
  ati72: state.theme.images?.ati72,
  ati120: state.theme.images?.ati120,
  ati152: state.theme.images?.ati152,
});

export default connect(mapStateToProps)(App);
