const initialState = {
  list: [], // list of { DosageID: Label }
  map: {}, // DosageID -> Label
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_FORMULARY': {
      const map = action.list.reduce((_map, { DosageID, Label }) => ({
        ..._map,
        [DosageID]: Label,
      }), {});
      const list = Object.keys(map).map((id) => ({ id, name: map[id] }));

      return { ...state, list, map };
    }
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
