import React from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

function Link({ href, text }) {
  const pageviewText = `/${text.toLowerCase().replaceAll(' ', '-')}`;
  return (
    <a
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'underline', color: 'black' }}
      href={href}
      onClick={() => ReactGA.send({ hitType: 'pageview', path: pageviewText })}
    >
      {text}
    </a>
  );
}

function Disclaimer() {
  const { theme } = useSelector(store => store);

  const defaultDisclaimer = [
    'Please read the full',
    { text: 'Prescribing Info', href: 'https://google.com' },
    'and',
    { text: 'Medication Guide', href: 'https://yahoo.com' },
    'for ',
    { themeVar: 'drugName' },
    '.',
  ];
  const disclaimer = theme.landingDisclaimer ? theme.landingDisclaimer : defaultDisclaimer;

  return (
    <h5 className="mt-3" style={{ color: 'black' }}>
      {disclaimer.map((e) => {
        if (typeof e === 'object') {
          const { text = '', href, themeVar, bold, italic } = e;
          let formattedText = text;
          if (bold) formattedText = <b>{formattedText}</b>;
          if (italic) formattedText = <i>{formattedText}</i>;
          if (themeVar && (theme[themeVar] || themeVar === 'drugName')) {
            // I don't know why the drug name is contained in an array in the theme...?
            // but it is, so...
            if (themeVar === 'drugName') {
              try {
                return theme.drugs[0];
              } catch {
                return formattedText;
              }
            }
            return theme[themeVar];
          }
          if (href) return (<Link href={href} text={text} />);
          return formattedText;
        }
        if (typeof e === 'string') {
          return e;
        }
        return '';
      })}
    </h5>
  );
}

export default Disclaimer;
