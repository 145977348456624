import React from 'react';
import BenefitPhase from '../BenefitPhase';

function DeductiblePhaseRow({
  years,
  formulary,
  name,
  children,
}) {
  return (
    <tr>
      {children}
      {years.map((year) => (
        <BenefitPhase dosage={formulary[year][name]} key={year} />
      ))}
    </tr>
  );
}

export default DeductiblePhaseRow;
