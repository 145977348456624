const initialState = {
  list: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_COUNTIES':
      return { ...state, list: [...action.list] };
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
