import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import sortAlphabetically from '../../util/sort';

// components
import CardModal from './CardModal';

function PlanSelection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    carriers: {
      logos,
      orgNames,
    },
    plans: { list: planList },
    responses: {
      carrier,
      plan,
    },
  } = useSelector(store => store);
  const logo = logos[carrier];

  // List of plans filtered by current carrier.
  const [plans, setPlans] = useState([]);

  function setResponse(name, value) {
    dispatch({ type: 'SET_RESPONSE', name, value });
  }

  useEffect(() => {
    // Filter out plans that aren't with current carrier.
    const filteredPlans = planList
      .filter(({ OrganizationName }) => {
        if (orgNames[carrier]) {
          const lowercasedOpts = orgNames[carrier].map((on) => on.toLowerCase());
          return lowercasedOpts.includes(OrganizationName.toLowerCase());
        }
        return true;
      })
      .sort((a, b) => {
        const left = a.PlanName.toLowerCase();
        const right = b.PlanName.toLowerCase();
        return sortAlphabetically(left, right);
      });

    const filteredPlansByQIDandName = [];

    filteredPlans.forEach((p1) => {
      const i = filteredPlansByQIDandName
        .findIndex((p2) => p2.QID === p1.QID && p2.PlanName === p1.PlanName);
      if (i <= -1) {
        filteredPlansByQIDandName.push(p1);
      } else {
        // if p2 year is more recent use the more recent year version of the plan
        const p2 = filteredPlans[i];
        if (p2.PlanYear > p1.PlanYear) {
          filteredPlansByQIDandName[i] = p2;
        }
      }
    });

    setPlans(filteredPlansByQIDandName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleChooseCommon() {
    const { PlanID, QID } = plans.reduce((min, _plan) => {
      if (_plan.TotalPlanPremium < min.TotalPlanPremium) {
        return _plan;
      }
      return min;
    }, plans[0]);

    await Promise.all([
      setResponse('plan', PlanID),
      setResponse('qid', QID),
    ]);
    navigate('/benefits');
  }

  function handlePlanSelect({ target: { value } }) {
    setResponse('plan', value);

    if (value) {
      const { QID } = planList.find(({ PlanID }) => PlanID === Number(value));
      setResponse('qid', QID);
      navigate('/benefits');
    }
  }

  // console.log('plans: ', plans);
  return (
    <div id="formulary-body" className="flex-column text-center my-md-5 p-xl-5 p-4 container">
      <CardModal />

      <h2 className="text-theme-header mb-4">
        Select your Medicare plan
      </h2>

      <div className="row justify-content-center">
        For best results, make sure the Plan ID you choose matches the Plan ID
        on your insurance card
      </div>

      <div className="row mb-4">
        <div style={{ width: '100%' }} className="mt-1 d-flex align-items-center justify-content-center">
          <div>
            <span>Tip: It looks like </span>
            <span className="text-nowrap">H1234 001</span>
          </div>
          <button
            type="button"
            className="btn btn-link btn-sm text-nowrap"
            color="link"
            size="sm"
            onClick={() => dispatch({ type: 'OPEN_MODAL', id: 'plansearch' })}
          >
            See Sample
          </button>
        </div>
      </div>

      <div className="px-xl-6 px-lg-5 px-md-4 px-2">
        <select
          id="planselect"
          className="form-select"
          type="select"
          value={plan}
          onChange={(e) => handlePlanSelect(e)}
          tabIndex={0}
        >
          <option value="">
            {`Select ${logo ? 'your' : carrier} plan...`}
          </option>
          {plans.map(({ PlanName, PlanID, QID }) => (
            <option value={PlanID} key={PlanID}>
              {`${PlanName} (${QID})`}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-4">
        <button
          type="button"
          className="btn btn-link btn"
          color="link"
          onClick={() => handleChooseCommon()}
        >
          I&apos;m not sure which plan I have, show me a common plan
        </button>
      </div>
    </div>
  );
}

export default PlanSelection;
