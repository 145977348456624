const initialState = {
  carrier: '', // logo url || display_name || org name
  plan: '',
  dosage: '',
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_THEME': {
      const drug = action.theme?.drugs?.length === 1 ? action.theme?.drugs?.[0] : state.drug;
      const dosage = state.dosage || action.theme.commonDosage || action.theme?.dosages?.[0]?.id;

      return { ...state, drug, dosage };
    }
    case 'SET_RESPONSE':
      return { ...state, [action.name]: action.value };
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
